import React from 'react'
import { navigate } from 'gatsby-link'

const API_KEY = process.env.MG_API_KEY

const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);
const client = mailgun.client({username: 'api', key: API_KEY, url:"https://api.eu.mailgun.net"});

const htmldata = `
<body
  leftmargin="0"
  marginwidth="0"
  topmargin="0"
  marginheight="0"
  offset="0"
  style="background-color: #e8d3d0; padding: 0; text-align: center"
  bgcolor="#e8d3d0"
>
  <table
    width="100%"
    id="outer_wrapper"
    style="background-color: #e8d3d0"
    bgcolor="#e8d3d0"
  >
    <tbody>
      <tr>
        <td>
          <!-- Deliberately empty to support consistent sizing and layout across multiple email clients. -->
        </td>
        <td width="600">
          <div
            id="wrapper"
            dir="ltr"
            style="
              margin: 0 auto;
              padding: 70px 0;
              width: 100%;
              max-width: 600px;
              -webkit-text-size-adjust: none;
            "
            width="100%"
          >
            <table
              border="0"
              cellpadding="0"
              cellspacing="0"
              height="100%"
              width="100%"
            >
              <tbody>
                <tr>
                  <td align="center" valign="top">
                    <div id="template_header_image">
                      <p style="margin-top: 0">
                        <img
                          src="https://admin.shroom4you.com/wp-content/uploads/2023/05/email_small_logo.png"
                          alt="Shroom Drink"
                          style="
                            border: none;
                            display: inline-block;
                            font-size: 14px;
                            font-weight: bold;
                            height: auto;
                            outline: none;
                            text-decoration: none;
                            text-transform: capitalize;
                            vertical-align: middle;
                            max-width: 75%;
                            margin-left: 0;
                            margin-right: 0;
                          "
                          border="0"
                        />
                      </p>
                    </div>
                    <table
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="100%"
                      id="template_container"
                      style="
                        background-color: #fff;
                        border: 1px solid #d1bebb;
                        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
                        border-radius: 3px;
                      "
                      bgcolor="#fff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <!-- Header -->
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              id="template_header"
                              style="
                                background-color: #281d47;
                                color: #fff;
                                border-bottom: 0;
                                font-weight: bold;
                                line-height: 100%;
                                vertical-align: middle;
                                font-family: 'Helvetica Neue', Helvetica, Roboto,
                                  Arial, sans-serif;
                                border-radius: 3px 3px 0 0;
                              "
                              bgcolor="#281d47"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    id="header_wrapper"
                                    style="padding: 36px 48px; display: block"
                                  >
                                    <h1
                                      style="
                                        font-family: 'Helvetica Neue', Helvetica,
                                          Roboto, Arial, sans-serif;
                                        font-size: 30px;
                                        font-weight: 300;
                                        line-height: 150%;
                                        margin: 0;
                                        text-align: left;
                                        text-shadow: 0 1px 0 #534a6c;
                                        color: #fff;
                                        background-color: inherit;
                                      "
                                      bgcolor="inherit"
                                    >
                                      Thanks for signing up!
                                    </h1>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- End Header -->
                          </td>
                        </tr>
                        <tr>
                          <td align="center" valign="top">
                            <!-- Body -->
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                              id="template_body"
                            >
                              <tbody>
                                <tr>
                                  <td>
                                      <img
                                        alt="contact"
                                        title="contact"
                                        width="150"
                                        height="auto"
                                        src="https://admin.shroom4you.com/wp-content/uploads/2024/03/newsletter_1.gif"
                                      />
                                  </td>
                                  <td style="background-color: whitesmoke">
                                    <h2
                                      style="
                                        color: #281d47;
                                        display: block;
                                        font-family: 'Helvetica Neue', Helvetica,
                                          Roboto, Arial, sans-serif;
                                        font-size: 18px;
                                        font-weight: bold;
                                        line-height: 130%;
                                        padding: 16px;
                                        text-align: left;
                                      "
                                    >
                                      Expect the unexpected,<br>
                                      Shroom newsletter will soon, land in Your
                                      inbox!
                                    </h2>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    valign="top"
                                    id="body_content"
                                    style="background-color: #fff"
                                    bgcolor="#fff"
                                  >
                                    <!-- Content -->
                                    <table
                                      border="0"
                                      cellpadding="20"
                                      cellspacing="0"
                                      width="100%"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            valign="top"
                                            style="padding: 32px 48px 32px"
                                          >
                                            <div
                                              id="body_content_inner"
                                              style="
                                                color: #636363;
                                                font-family: 'Helvetica Neue',
                                                  Helvetica, Roboto, Arial,
                                                  sans-serif;
                                                font-size: 14px;
                                                line-height: 150%;
                                                text-align: left;
                                              "
                                              align="left"
                                            >
                                              <p style="margin: 0 0 16px">
                                                We hope, You won't be
                                                disappointed. Enjoy the discount
                                                of -5% on your next order with
                                                the
                                                <code> shroom_bonus</code>
                                                code.
                                              </p>

                                              <h2
                                                style="
                                                  color: #281d47;
                                                  display: block;
                                                  font-family: 'Helvetica Neue',
                                                    Helvetica, Roboto, Arial,
                                                    sans-serif;
                                                  font-size: 18px;
                                                  font-weight: bold;
                                                  line-height: 130%;
                                                  margin: 0 0 18px;
                                                  text-align: left;
                                                "
                                              >
                                                Explore more
                                              </h2>
                                              <p style="text-align: left">
                                                While you wait, why not visit
                                                our
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  href="https://shroom4you.com/blog/"
                                                  class="link"
                                                  style="
                                                    font-weight: normal;
                                                    text-decoration: underline;
                                                    color: #281d47;
                                                  "
                                                  >blog</a
                                                >
                                                or learn more about
                                                <a
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  class="link"
                                                  style="
                                                    font-weight: normal;
                                                    text-decoration: underline;
                                                    color: #281d47;
                                                  "
                                                  href="https://shop.shroom4you.com"
                                                >
                                                our drinks and
                                                accessories.</a>
                                              </p>
                                              <p
                                                style="
                                                  text-align: center;
                                                  font-size: 10px;
                                                  margin-top: 10px;
                                                  margin-bottom: 5px;
                                                "
                                              >
                                                We hope you like our content. If
                                                not here you can
                                                <a
                                                  target="_blank"
                                                  class="link"
                                                  style="
                                                    font-weight: normal;
                                                    text-decoration: underline;
                                                    color: #281d47;
                                                  "
                                                  rel="noopener noreferrer"
                                                  href="https://shop.shroom4you.com/contact/"
                                                  >get in touch.</a
                                                >
                                              </p>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div
                                      style="
                                        background-color: #281d47;
                                        text-align: center;
                                        padding: 15px;
                                        margin: 0;
                                      "
                                    >
                                      <p>
                                        <a
                                          href="https://www.instagram.com/shroom4you"
                                          aria-label="instagram"
                                          target="_blank"
                                          rel="noreferrer"
                                          >
                                          <img width="35" src="https://admin.shroom4you.com/wp-content/uploads/2024/03/insta.png" /></a>
                                        &nbsp;&nbsp;
                                        <a
                                          href="https://www.linkedin.com/company/shroom4you/"
                                          aria-label="linkedin"
                                          target="_blank"
                                          rel="noreferrer"
                                          ><img width="35" src="https://admin.shroom4you.com/wp-content/uploads/2024/03/linkedin.png" /></a>
                                        &nbsp;&nbsp;
                                        <a
                                          href="https://www.facebook.com/shroom4you"
                                          aria-label="facebook"
                                          target="_blank"
                                          rel="noreferrer"
                                          ><img width="35" src="https://admin.shroom4you.com/wp-content/uploads/2024/03/face.png" /></a>
                                      </p>
                                    </div>
                                    <!-- End Content -->
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <!-- End Body -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    <!-- Footer -->
                    <table
                      border="0"
                      cellpadding="10"
                      cellspacing="0"
                      width="100%"
                      id="template_footer"
                    >
                      <tbody>
                        <tr>
                          <td
                            valign="top"
                            style="padding: 0; border-radius: 6px"
                          >
                            <table
                              border="0"
                              cellpadding="10"
                              cellspacing="0"
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    colspan="2"
                                    valign="middle"
                                    id="credit"
                                    style="
                                      border-radius: 6px;
                                      border: 0;
                                      color: #111111;
                                      font-family: 'Helvetica Neue', Helvetica,
                                        Roboto, Arial, sans-serif;
                                      font-size: 12px;
                                      line-height: 150%;
                                      text-align: center;
                                      padding: 24px 0;
                                    "
                                    align="center"
                                  >
                                    <p style="margin: 0 0 16px">
                                      Shroom Drink<br> Learn more on
                                      <a
                                        href="https://shroom4you.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style="
                                          color: #281d47;
                                          font-weight: normal;
                                          text-decoration: underline;
                                        "
                                        >shroom4you.com</a
                                      >
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- End Footer -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
        <td>
          <!-- Deliberately empty to support consistent sizing and layout across multiple email clients. -->
        </td>
      </tr>
    </tbody>
  </table>
</body>
`

function encode(data) {
  const formData = new FormData()


  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData

}


const newsDestroy = () => {
  //console.log('destroy modal');

  document.getElementById('newsletter').style.transition = 'opacity 1s';
  document.getElementById('newsletter').style.opacity = '0';
  setTimeout(function(){document.getElementById('newsletter').style.display = 'none';
  },500);
}


	const newsEnter = () => {
		//console.log('enter modal');
		document.getElementById('newsletter').style.display = 'block';
		document.getElementById('newsletter').style.transition = 'opacity 0.5s';

		setTimeout(function(){document.getElementById('newsletter').style.opacity = '1';},500);
	}




class NewsletterForm extends React.Component {

  componentDidMount() {
    // This code will run after the component mounts

    //console.log('Component did mount');
    //const userSubscribed = localStorage.getItem('userSubscribed');
    /*console.log(userSubscribed);
      if (userSubscribed !== "true") {
        // Display the pop-up after 5 seconds
        setTimeout(() => {
          newsEnter();
          // Set userSubscribed in localStorage
          localStorage.setItem('userSubscribed', 'true');
        }, 15000);
      }*/

    }

  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
    .then(() => {

      const messageData = {
        from: 'Shroom Drinks <shop@shroom4you.com>',
        to: document.getElementById('e-mail-adress').value,
        subject: 'Thank you for subscribing!',
        html: htmldata
      };

      client.messages.create('shop.shroom4you.com', messageData)
       .then((res) => {
         console.log(res);
       })
       .catch((err) => {
         console.error(err);
       });

      newsDestroy();
      navigate('/thank-you/')
    })
      .catch(error => alert(error))
    }

  render() {
    return (
      <div id='newsletter'
      style={{display:'none', transition:'opacity 1s',opacity:'0',position:'fixed',top:'0px',left:'0px',height:'100%',width:'100%',backgroundColor:'rgba(255,255,255,0.85)',zIndex:'20000',overflow:'scroll'}}>
        <div style={{marginLeft:'5%',marginRight:'5%',paddingTop:'5%'}}>
      <form
      name="Newsletter"
      id = "newsletter-form"
      method="post"
      action="/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={this.handleSubmit}
      style={{marginLeft:'auto',marginRight:'auto',minHeight:'350px',maxWidth:'600px',borderRadius:'25px',padding:'10px',paddingTop:'35px',backgroundImage:`url(https://admin.shroom4you.com/wp-content/uploads/2024/01/news2.jpg)`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
      <button className='btn btn-danger' style={{fontSize:'20px',marginTop:'-30px'}} onClick={newsDestroy} >&nbsp;x&nbsp;</button>


      <div>
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="newsletter" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field"
          onChange={this.handleChange}
          />
        </label>
      </div>
      <h3 className='spec-h' style={{color:'whitesmoke',textAlign:'center'}}>Hey, why not sign up for our newsletter? </h3>
      <br />
      <div style={{textAlign:'center',marginLeft:'30px',marginRight:'30px',backgroundColor:'rgba(15,15,15,.5)',borderRadius:'25px'}}>
      <br />
      <p className='subtitle' style={{color:'whitesmoke'}}> <span style={{backgroundColor:'white',color:'purple'}} className="spec-h">Get special offers and promo codes</span><br />
       Stay up to date with us and our merch!</p>
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
      <input className='form-control' required={true} onChange={this.handleChange} name='adres email' id="e-mail-adress" type='email' placeholder='E-mail adress' style={{margin:'auto',maxWidth:'200px',border:'1px solid #281D47'}}>
      </input>
      </div>
      <br />

      {/*	GDPR Compiliant*/}
      <div className="form-check mt-2">
      <label>
      <input onChange={this.handleChange} required={true} value="yes" className="form-check-input" name="gdpr" type="checkbox"/>
      <span style={{fontSize:'12px',color:'#fefefe'}}>I consent to the processing of my personal data by Shroom sp. z o.o. for the purpose of direct marketing and sending information regarding special offers and information via electronic communication tools. Information on how the data is processed is available in <a href="/privacy-policy/">Privacy Policy</a>.</span>
      </label>
      </div>

      <br />

      <button className="btn btn-primary" type="submit" >
      Subscribe!
      </button>
      <br />
      <br />
      </div>
      <div style={{textAlign:'center'}}>
      <br />

      <a className='text-dark' href="/privacy-policy/">Privacy policy</a>
      <br />
      <br />
        </div>
      </div>
      </form>
      </div>
    </div>
    )
  }
}



export default NewsletterForm
