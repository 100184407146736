import React from 'react'
import jwt_decode from 'jwt-decode'
import { useState } from 'react'
import Link from "gatsby-link";

const CheckoutLink = (props) => {

  let color = props.color
  const [session] = useState(() => {
    try {
      // Get the session from whatever mechanism you're storing it in: localstorage, context, state, etc
      const token = localStorage.getItem("woo-session");

      // jtw-decode is an open-source package
      const decoded = jwt_decode(token)

      return decoded.data.customer_id
    } catch (error) {
      console.log(error.message)
      return null
    }
  })

  return <Link className="checkout" style={{color:`${color}`}}  to={'/checkout/'}>Checkout</Link>
}

export default CheckoutLink;
