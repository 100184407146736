/**
 * External dependencies.
 */
import React from "react";
import { useEffect, useState } from "react";
import Link from "gatsby-link";
import { each, isEmpty } from "lodash";
import classnames from "classnames";
import CheckoutButton from "../checkout-btn";
import DropdownIcon from "../icons/dropdown-icon";
import Poland from "../../images/poland.png";
// import England from '../../images/usa.png';
import { normalizePath } from "../../utils/functions";
import CartIcon from "../cart/cart-icon";
import WishListIcon from "../wishlist/wishlist-icon";
import UserIcon from "../icons/user-icon";
import ReloadLink from "../../utils/reloadLink";

const Nav = (props) => {
  const { headerMenuItems } = props;

  const [menuVisible, setMenuVisibility] = useState(false);
  const [menuState, setMenuState] = useState({});

  // Eslint disable as headerMenuItems is available from the props so does not have a missing dependency.
  /* eslint-disable */
  useEffect(() => {
    if (Object.keys(headerMenuItems.edges).length) {
      const newMenuState = {};

      each(headerMenuItems.edges, (item) => {
        newMenuState[item.node.databaseId] = { isOpen: false };
      });

      setMenuState(newMenuState);
    }

    // for window scrolldown event
    window.onscroll = () => {
      if (window.scrollY >= 45) {
        document.querySelector(".site-header-container").style.top = "0px";
        document.querySelector(".site-header-container").style.position =
          "fixed";
        document.querySelector(".main-container").style.marginTop = "45px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div > nav"
        ).style.top = "65px";
      } else {
        document.querySelector(".site-header-container").style.position =
          "relative";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div > nav"
        ).style.top = "110px";
        document.querySelector(".main-container").style.marginTop = "0px";
      }
    };
  }, []);

  /* eslint-enable */

  const handleSubMenuOpen = (event, databaseId) => {
    event.stopPropagation();

    setMenuState({
      ...menuState,
      [databaseId]: { isOpen: !menuState[databaseId].isOpen },
    });
  };

  const menuButtonClasses = classnames(
    "header-nav-menu-btn hamburger header-nav__menu-btn hamburger--slider",
    {
      "is-active": menuVisible,
    }
  );

  const toggleMenu = () => {
    setMenuVisibility(!menuVisible);
    document.body.classList.toggle("mobile-menu-open");
  };

  const setLanguage = () => {
    localStorage.setItem("language", "PL");
  };

  return (
    <>
      <nav className={`header-nav ${menuVisible ? "menu-visible" : ""}`}>
        <div className="header-nav__wrap">
          {!isEmpty(headerMenuItems.edges) && !isEmpty(menuState) ? (
            <ul className="header-nav__wrap">
              {headerMenuItems.edges.map((menu) => {
                const hasChildren =
                  null !== menu.node.childItems.nodes
                    ? menu.node.childItems.nodes.length
                    : false;
                const parentMenuLink = (
                  <Link className="header-nav__menu-link" to={menu.node.url}>
                    {menu.node.label}
                  </Link>
                );

                return (
                  <li
                    key={menu.node.databaseId}
                    className={`header-nav__menu-item ${
                      hasChildren ? "menu-has-children" : ""
                    } ${
                      menuState[menu.node.databaseId].isOpen
                        ? "child-menu-open"
                        : ""
                    }`}
                  >
                    {hasChildren ? (
                      <span className="header-nav__menu-link-container-with-arrow">
                        {parentMenuLink}
                        <button
                          className="header-nav__toggle-menu-btn icon-button"
                          onClick={(event) =>
                            handleSubMenuOpen(event, menu.node.databaseId)
                          }
                          onKeyDown={(event) =>
                            handleSubMenuOpen(event, menu.node.databaseId)
                          }
                        >
                          <DropdownIcon />
                        </button>
                      </span>
                    ) : (
                      parentMenuLink
                    )}

                    {/* Child Menu */}
                    {hasChildren ? (
                      <ul
                        className={`header-nav__submenu ${
                          menuState[menu.node.databaseId].isOpen
                            ? "child-menu-open"
                            : ""
                        }`}
                      >
                        {menu.node.childItems.nodes.map((subMenu) => (
                          <li
                            className="header-nav__submenu-item"
                            key={subMenu.databaseId}
                          >
                            <Link
                              className="header-nav__submenu-link"
                              to={normalizePath(subMenu.url)}
                            >
                              {subMenu.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                );
              })}

              <li className={`header-nav__menu-item`}>
                <ReloadLink
                  className="header-nav__menu-link"
                  to="/where-to-buy/"
                >
                  Where to buy?
                </ReloadLink>
              </li>

              <li className={`header-nav__menu-item`}>
                <Link className="header-nav__menu-link" to="/shroomscriptions/">
                  Shroomscriptions
                </Link>
              </li>

              <template id="mob-btns">
                {localStorage.getItem("woo-session") &&
                localStorage.getItem("woo-next-cart") !== "null" ? (
                  <>
                    <li>
                      <CheckoutButton />
                    </li>
                  </>
                ) : null}
                <li>
                  <Link
                    style={{ margin: "0 8px" }}
                    className="contact"
                    to="/contact/"
                  >
                    <span className="btn btn-outline-primary">Contact</span>
                  </Link>
                </li>
              </template>
            </ul>
          ) : null}
        </div>
      </nav>
      <div className="cart-icon-wrap">
        <UserIcon />
        <WishListIcon />
        <CartIcon />

        <span className="header-nav__menu-item" style={{ minWidth: "22px" }}>
          <a onClick={setLanguage} id="lang-switch" href="/pl/">
            <img
              src={Poland}
              width="22px"
              height="22px"
              id="flag-img"
              alt="language switcher"
            />
          </a>
        </span>

        {/*Burger menu*/}
        <button
          className={menuButtonClasses}
          type="button"
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner">
              <span className="screen-reader-text">Toogle Menu</span>
            </span>
          </span>
        </button>
      </div>
    </>
  );
};

export default Nav;
